"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: '佐原めぐり',
    organizationId: 'sawarameguri3ZNZmx4J',
    version: '1.0.0',
    ionicAppId: '',
    applicationKey: 'sawarameguri',
    applicationType: 'standalone',
    artistId: '2Wph1uPManOBSqhOFrEBogKAjFm2',
    languages: ['ja', 'en'],
    ios: {
        bundleId: 'com.utoniq.sawarameguri',
        appId: '',
    },
    android: {
        bundleId: 'com.utoniq.sawarameguri',
    },
    platform: ['web'],
    custom: {
        modules: {
            tutorial: false,
            trade: false,
            membership: false,
            thread: true,
            prepaidPoint: null,
            gacha: false,
            nft: false,
            liveStream: false,
            item: true,
            store: true,
            campaign: false,
            mission: true,
            resale: false,
            preSale: false,
            event: false,
            cart: true,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial01.jpg',
            'images/tutorial/Tutorial02.jpg',
            'images/tutorial/Tutorial03.jpg',
            'images/tutorial/Tutorial04.jpg',
        ],
        globalTabs: [],
        artistTabs: ['home', 'collections', 'threads', 'myProfile'],
    },
    deeplinkUrl: 'https://sawara-meguri.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/3562308',
    storagePath: 'sawarameguri',
    dynamicLinkPrefix: 'sawarameguri',
    deeplinkProd: 'sawara-meguri.utoniq.com',
};
exports.default = appConfig;
